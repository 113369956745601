<template>
  <v-navigation-drawer v-model="drawer" app>
    <div class="d-flex flex-column justify-space-between" style="height: 100%;">
      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
          <v-list-item-action>
            <v-icon v-text="item.icon" />
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-for="item in groupItems"
          :key="item.title"
          :prepend-icon="item.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title v-text="item.title" />
          </template>

          <v-list-item v-for="child in item.children" :key="child.title" :to="child.to" link>
            <v-list-item-title v-text="child.title" />

            <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list dense>
        <v-list-item v-for="item in accountItems" :key="item.title" :to="item.to" link>
          <v-list-item-action>
            <v-icon v-text="item.icon" />
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  computed: {
    drawer: {
      get() {
        return this.$store.state.layout.showDrawer;
      },

      set(value) {
        this.$store.commit('layout/setDrawer', value);
      },
    },

    items() {
      const officeAdminItems = [
        {
          icon: 'mdi-account-group',
          title: 'Clientes',
          to: { name: 'customer-list' },
        },
        {
          icon: 'mdi-hanger',
          title: 'Productos',
          to: { name: 'product-list' },
        },
        {
          icon: 'mdi-account-tie',
          title: 'Personal',
          to: { name: 'employee-list' },
        },
        {
          icon: 'mdi-rv-truck',
          title: 'Vehículos',
          to: { name: 'vehicle-list' },
        },
        {
          icon: 'mdi-ticket-percent',
          title: 'Descuentos',
          to: { name: 'discount-list' },
        },
      ];
      const ownerItems = [
        {
          icon: 'mdi-store',
          title: 'Sucursales',
          to: { name: 'office-list' },
        },
        {
          icon: 'mdi-account-group',
          title: 'Clientes',
          to: { name: 'customer-list' },
        },
      ];
      const commonItems = [
        {
          icon: 'mdi-home',
          title: 'Órdenes',
          to: { name: 'order-list' },
        },
      ];

      let items = [...commonItems];

      if (this.can('office_admin')) {
        items.push(...officeAdminItems);
      }

      if (this.can('owner')) {
        items = [...ownerItems];
      }

      if (this.can('operator', 'driver')) {
        items.push({
          icon: 'mdi-file-document',
          title: 'Reporte de órdenes',
          to: { name: 'operation-order' },
        });
      }

      return items;
    },

    accountItems() {
      const items = [
        {
          icon: 'mdi-cog',
          title: 'Cuenta',
          to: { name: 'account' },
        },
        {
          icon: 'mdi-account-circle',
          title: this.$store.getters.profile?.full_name,
          to: '',
        },
      ];

      return items;
    },

    groupItems() {
      if (!this.can('owner', 'office_admin')) return [];

      return [
        {
          icon: 'mdi-file-document',
          title: 'Operaciones',
          children: [
            {
              icon: 'mdi-file-document',
              title: 'Reporte',
              to: { name: 'operation-report' },
            },
            {
              icon: 'mdi-file-document',
              title: 'Resumen',
              to: { name: 'operation-summary' },
            },
            {
              icon: 'mdi-file-document',
              title: 'Orden',
              to: { name: 'operation-order' },
            },
          ],
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>

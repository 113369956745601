/**
 * Global configuration variables
 */
export default {
  HOST: process.env.VUE_APP_API_HOST,
  DEBUG: process.env.NODE_ENV === 'development',

  openpay: {
    API_KEY: process.env.VUE_APP_OPENPAY_PUBLIC_KEY,
    ID: process.env.VUE_APP_OPENPAY_ID,
    SANDBOX: !!process.env.VUE_APP_OPENPAY_SANDBOX,
  },

  // Storage keys
  storage: {
    ACCESS_TOKEN: 'access_token',
    REFRESH_TOKEN: 'refresh_token',
    PROFILE: 'profile',
  },

  // User's roles
  roles: {
    CUSTOMER: {
      key: 'customer',
      label: 'Cliente',
    },
    DRIVER: {
      key: 'driver',
      label: 'Chofer',
    },
    OPERATOR: {
      key: 'operator',
      label: 'Operador',
    },
    OFFICE_ADMIN: {
      key: 'office_admin',
      label: 'Administrador de oficina',
    },
    OWNER: {
      key: 'owner',
      label: 'Dueño',
    },
  },
};

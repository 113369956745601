const initialState = () => ({});

export default {
  namespaced: true,

  state: initialState(),

  mutations: {},

  actions: {},
};

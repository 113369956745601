<template>
  <v-app id="inspire">
    <Drawer />

    <v-app-bar
      app
      color="indigo"
      dark
    >
      <v-app-bar-nav-icon @click.stop="$store.commit('layout/toggleDrawer')" />

      <v-toolbar-title>
        <router-link to="/">
          <v-img
            alt="Wapidu logo"
            src="@/assets/img/logo_white.png"
            width="150"
          />
        </router-link>
      </v-toolbar-title>

      <v-spacer />

      <div class="d-none d-sm-block mr-4">
        <v-btn text :to="{ name: 'account' }">
          <v-icon left>mdi-account</v-icon>
          {{ $store.getters.profile && $store.getters.profile.full_name }}
        </v-btn>
      </div>

      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="$store.dispatch('auth/logout')"
          >
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>

        <span>Cerrar sesión</span>
      </v-tooltip>
    </v-app-bar>

    <v-main>
      <Notification />

      <v-container>
        <v-row>
          <v-col>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer
      color="indigo"
      app
    >
      <span class="white--text">&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
// Components
import Notification from '@/components/notification/index.vue';
import Drawer from './components/Drawer.vue';

export default {
  name: 'Layout',

  components: {
    Drawer,
    Notification,
  },
};
</script>

<style lang="scss" scoped>

</style>

const initialState = () => ({
  message: '',
  show: false,
  type: 'success',
});

export default {
  namespaced: true,

  state: initialState(),

  mutations: {
    setMessage(state, message) {
      state.message = message;
    },

    setShow(state, show) {
      state.show = show;
    },

    setType(state, type) {
      state.type = type;
    },

    reset(state) {
      const newState = initialState();

      Object.entries(newState).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },

  actions: {
    notifyError(
      { commit, dispatch },
      message = 'Ocurrió un problema. Por favor, vuelve a intentar.',
    ) {
      commit('setMessage', message);
      commit('setType', 'error');
      commit('setShow', true);
      dispatch('clear');
    },

    notifySuccess({ commit, dispatch }, message = 'Guardado exitósamente.') {
      commit('setMessage', message);
      commit('setType', 'success');
      commit('setShow', true);
      dispatch('clear');
    },

    clear({ commit }) {
      const SECOND = 1000;
      setTimeout(() => commit('reset'), 3 * SECOND);
    },
  },
};

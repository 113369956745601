// Components
import Layout from '@/views/layout/index.vue';

export default {
  path: '/discounts',
  component: Layout,
  children: [
    {
      path: '',
      name: 'discount-list',
      component: () => import(/* webpackChunkName: 'discount-list' */ '@/views/discount/List.vue'),
    },
    {
      path: ':uuid?/form',
      name: 'discount-form',
      component: () => import(/* webpackChunkName: 'discount-form' */ '@/views/discount/Form.vue'),
      props: true,
    },
    {
      path: ':uuid',
      name: 'discount-detail',
      component: () =>
        import(/* webpackChunkName: 'discount-detail' */ '@/views/discount/Detail.vue'),
      props: true,
    },
  ],
};

<template>
  <validation-provider :rules="rules" :vid="vid" v-slot="{ errors }" ref="provider">
    <v-text-field
      :error-messages="errors"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </validation-provider>
</template>

<script>
// Mixins
import FieldMixin from '@/mixins/FieldMixin';

export default {
  name: 'TextField',

  mixins: [FieldMixin],
};
</script>

<style lang="scss" scoped>

</style>

// Libraries
import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
import vuetify from '@/plugins/vuetify';

// Local JS
import '@/filters';
import '@/plugins/vee-validate';
import vue2filtersConfig from '@/plugins/vue2filters';
import RoleMixin from '@/mixins/RoleMixin';
import router from '@/router';
import store from '@/store';

// Local Styles
import '@/assets/scss/app.scss';

// Components
import App from '@/App.vue';
import Form from '@/components/validation/Form.vue';
import ComboField from '@/components/validation/ComboField.vue';
import FileField from '@/components/validation/FileField.vue';
import SelectField from '@/components/validation/SelectField.vue';
import TextAreaField from '@/components/validation/TextAreaField.vue';
import TextField from '@/components/validation/TextField.vue';

// Global Components
Vue.component('Form', Form);
Vue.component('ComboField', ComboField);
Vue.component('FileField', FileField);
Vue.component('SelectField', SelectField);
Vue.component('TextAreaField', TextAreaField);
Vue.component('TextField', TextField);

// Plugins
Vue.use(Vue2Filters, vue2filtersConfig);

// Mixins
Vue.mixin(RoleMixin);

// Config
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

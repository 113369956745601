// Components
import Layout from '@/views/layout/index.vue';

export default {
  path: '/offices',
  component: Layout,
  children: [
    {
      path: '',
      name: 'office-list',
      component: () => import(/* webpackChunkName: 'office-list' */ '@/views/office/List.vue'),
    },
    {
      path: ':uuid?/form',
      name: 'office-form',
      component: () => import(/* webpackChunkName: 'office-form' */ '@/views/office/Form.vue'),
      props: true,
    },
    {
      path: ':uuid',
      name: 'office-detail',
      component: () => import(/* webpackChunkName: 'office-detail' */ '@/views/office/Detail.vue'),
      props: true,
    },
  ],
};

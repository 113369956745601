// Config
import config from '@/config';

export default {
  // Auth
  token: (state) => state.auth.tokens.access,
  profile: (state) => state.auth.profile || null,
  role: (_, getters) => getters.profile?.user?.role,
  isCustomer: (_, getters) => getters.role === config.roles.CUSTOMER.key,
  isDriver: (_, getters) => getters.role === config.roles.DRIVER.key,
  isOfficeAdmin: (_, getters) => getters.role === config.roles.OFFICE_ADMIN.key,
  isOperator: (_, getters) => getters.role === config.roles.OPERATOR.key,
  isOwner: (_, getters) => getters.role === config.roles.OWNER.key,

  // Main
  loading: (state) => state.loading,
};

// API
// import AuthAPI from '@/api/auth';

// Config
import config from '@/config';

// Router
import router from '@/router';
import interceptors from '@/router/interceptors';

// Utils
import Storage from '@/utils/storage';

const storage = new Storage();
// const api = new AuthAPI();

const initialState = () => ({
  tokens: {
    access: null,
    refresh: null,
  },
  profile: null,
});

export default {
  namespaced: true,

  state: initialState(),

  mutations: {
    setAccessToken(state, token) {
      state.tokens.access = token;
    },

    setRefreshToken(state, token) {
      state.tokens.refresh = token;
    },

    setProfile(state, profile) {
      state.profile = profile;
      storage.saveItem(config.storage.PROFILE, profile);
    },
  },

  actions: {
    getStoragedTokens({ commit }) {
      const accessToken = storage.getItem(config.storage.ACCESS_TOKEN);
      const refreshToken = storage.getItem(config.storage.REFRESH_TOKEN);
      const profile = storage.getItem(config.storage.PROFILE);

      commit('setAccessToken', accessToken);
      commit('setRefreshToken', refreshToken);
      commit('setProfile', profile);
    },

    logout({ commit }) {
      commit('setAccessToken', null);
      commit('setRefreshToken', null);
      commit('setProfile', null);
      storage.removeItem(config.storage.ACCESS_TOKEN);
      storage.removeItem(config.storage.REFRESH_TOKEN);
      storage.removeItem(config.storage.PROFILE);

      const routeName = router.currentRoute.name;
      if (interceptors.shouldRedirect(routeName)) {
        router.push({ name: 'landing' });
      }
    },

    setTokens({ commit }, tokens) {
      commit('setAccessToken', tokens.access);
      commit('setRefreshToken', tokens.refresh);
      storage.saveItem(config.storage.ACCESS_TOKEN, tokens.access);
      storage.saveItem(config.storage.REFRESH_TOKEN, tokens.refresh);
    },

    // async verifyToken({ state, dispatch }) {
    //   dispatch('getStoragedTokens');

    //   try {
    //     await api.verifyToken({
    //       token: state.tokens.access,
    //     });
    //   } catch (error) {
    //     dispatch('logout');
    //   }
    // },
  },
};

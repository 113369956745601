// Store
import store from '@/store';

const whiteList = [
  '404',
  'landing',
  'login',
  'notice-of-privacy',
  'password-recovery-email',
  'password-recovery-reset',
  'signup',
  'verify-email',
];

const redirectIfAuth = [
  'landing',
  'login',
  'signup',
];

export default {
  async beforeEach(to, from, next) {
    store.dispatch('auth/getStoragedTokens');

    if (whiteList.includes(to.name)) {
      if (redirectIfAuth.includes(to.name) && store.getters.token) {
        if (store.getters.isOwner) {
          next('/offices');
        } else {
          next('/orders');
        }
        return;
      }

      next();
      return;
    }

    if (!store.getters.token) {
      next({ name: 'login' });
      return;
    }

    next();
  },

  afterEach() {},

  /**
   * Should redirect to landing if route is not in whitelist.
   */
  shouldRedirect(routeName) {
    return !whiteList.includes(routeName) && routeName !== 'landing';
  },
};

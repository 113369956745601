// Components
import Layout from '@/views/layout/index.vue';

const props = (router) => {
  const p = router.params;
  p.role = 'customer';

  return p;
};

export default {
  path: '/customers',
  component: Layout,
  children: [
    {
      path: '',
      name: 'customer-list',
      component: () => import(/* webpackChunkName: 'customer-list' */ '@/views/customer/List.vue'),
      props,
    },
    {
      path: ':uuid?/form',
      name: 'customer-form',
      component: () => import(/* webpackChunkName: 'customer-form' */ '@/views/customer/Form.vue'),
      props,
    },
    {
      path: ':uuid',
      name: 'customer-detail',
      component: () => import(/* webpackChunkName: 'customer-detail' */ '@/views/customer/Detail.vue'),
      props,
    },
  ],
};

// Libraries
import { ValidationProvider } from 'vee-validate';

export default {
  props: ['value', 'rules', 'vid'],

  components: {
    ValidationProvider,
  },

  methods: {
    setErrors(errors) {
      this.$refs.provider.setErrors(errors);
    },
  },
};

// Components
import Layout from '@/views/layout/index.vue';

export default {
  path: '/account',
  component: Layout,
  children: [
    {
      path: '',
      name: 'account',
      component: () => import(/* webpackChunkName: 'account' */ '@/views/account/index.vue'),
    },
  ],
};

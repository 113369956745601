// Libraries
import Vue from 'vue';
import Vuex from 'vuex';

// Own JS
import getters from './getters';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
  },

  mutations: {
    showLoader(state) {
      state.loading = true;
    },

    hideLoader(state) {
      state.loading = false;
    },
  },

  actions: {
  },

  getters,
  modules,
});

/* eslint-disable camelcase, object-curly-newline */
// Libraries
import { extend } from 'vee-validate';
import {
  confirmed,
  email,
  integer,
  max,
  min,
  min_value,
  length,
  required,
} from 'vee-validate/dist/rules';

extend('confirmed', {
  ...confirmed,
  message: 'Las contraseñas no coinciden',
});

extend('email', {
  ...email,
  message: 'Correo inválido',
});

extend('integer', {
  ...integer,
  message: 'El valor debe ser un número',
});

extend('max', {
  ...max,
  message: 'La longitud máxima es {length}',
});

extend('min', {
  ...min,
  message: 'La longitud mínima es {length}',
});

extend('min_value', {
  ...min_value,
  message: 'El valor mínimo es {min}',
});

extend('length', {
  ...length,
  message: 'La longitud debe ser de {length}',
});

extend('required', {
  ...required,
  message: 'Campo requerido',
});

extend('schedule', (value) => {
  const [hour, minute] = value.split(':');

  // 09:00 hrs
  const initialTime = new Date(0, 0, 0, 9, 0, 0, 0);
  // 20:00 hrs
  const endTime = new Date(0, 0, 0, 20, 0, 0, 0);
  const time = new Date(0, 0, 0, Number(hour), Number(minute), 0, 0);

  if (initialTime <= time && time < endTime) return true;

  return 'El horario de servicio es de 09:00 a 20:00 hrs';
});

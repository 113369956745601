// Components
import Layout from '@/views/layout/index.vue';

export default {
  path: '/products',
  component: Layout,
  children: [
    {
      path: '',
      name: 'product-list',
      component: () => import(/* webpackChunkName: 'product-list' */ '@/views/product/List.vue'),
    },
    {
      path: ':uuid?/form',
      name: 'product-form',
      component: () => import(/* webpackChunkName: 'product-form' */ '@/views/product/Form.vue'),
      props: true,
    },
    {
      path: ':uuid',
      name: 'product-detail',
      component: () => import(/* webpackChunkName: 'product-detail' */ '@/views/product/Detail.vue'),
      props: true,
    },
  ],
};

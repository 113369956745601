// Store
import store from '@/store';

export default {
  methods: {
    /**
     * Verify that user's role is in allowed roles.
     */
    can(...roles) {
      return roles.includes(store.getters.role);
    },

    /**
     * Verify that user's role is not in forbidden roles.
     */
    cannot(...roles) {
      return !roles.includes(store.getters.role);
    },
  },
};

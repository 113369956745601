/* eslint-disable import/prefer-default-export */
export const formatDate = (value, { time = false } = {}) => {
  if (!value) return '---';

  // Avoid mistransforming date when it has not time.
  const fixedDate = value.toString().includes(':') ? value : `${value}T12:00:00.000`;
  const date = new Date(fixedDate);
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  };
  let timeComplement = '';

  if (time) {
    options.hour = '2-digit';
    options.minute = '2-digit';
    options.hour12 = false;
    timeComplement = ' hrs';
  }

  return Intl.DateTimeFormat('es-MX', options).format(date) + timeComplement;
};

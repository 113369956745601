<template>
  <section>
    <Loader />
    <router-view />
  </section>
</template>

<script>
// Components
import Loader from '@/components/loader/index.vue';

export default {
  name: 'App',

  components: {
    Loader,
  },

  // async created() {
  //   await this.$store.dispatch('auth/verifyToken');
  // },
};
</script>

// Components
import Layout from '@/views/layout/index.vue';

export default {
  path: '/operations',
  component: Layout,
  children: [
    {
      path: 'report',
      name: 'operation-report',
      component: () =>
        import(/* webpackChunkName: 'operation-report' */ '@/views/operation/Report.vue'),
    },
    {
      path: 'summary',
      name: 'operation-summary',
      component: () =>
        import(/* webpackChunkName: 'operation-summary' */ '@/views/operation/Summary.vue'),
    },
    {
      path: 'order',
      name: 'operation-order',
      component: () =>
        import(/* webpackChunkName: 'operation-order' */ '@/views/operation/Order.vue'),
    },
  ],
};

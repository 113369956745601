import config from '@/config';

/* eslint-disable import/prefer-default-export */
export const getRoleDisplay = (value) => {
  let roleName = '';
  Object.values(config.roles).forEach((role) => {
    if (role.key === value) {
      roleName = role.label;
    }
  });

  return roleName;
};

<template>
  <v-overlay
    :value="$store.getters.loading"
    :opacity="0.5"
    :z-index="999"
  >
    <v-progress-circular
      size="80"
      style="margin-bottom: 1.5em;"
      indeterminate
    />

    <p>Cargando...</p>
  </v-overlay>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style lang="scss" scoped>

</style>

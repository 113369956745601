<template>
  <v-alert
    v-model="showNotification"
    class="notification"
    :type="$store.state.notification.type"
    transition="scale-transition"
    dismissible
  >
    {{ $store.state.notification.message }}
  </v-alert>
</template>

<script>
export default {
  name: 'Notification',

  computed: {
    showNotification: {
      get() {
        return this.$store.state.notification.show;
      },

      set(value) {
        this.$store.commit('notification/setShow', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  position: fixed;
  width: 100%;
  z-index: 100;
}
</style>

/* eslint-disable implicit-arrow-linebreak */

// Libraries
import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import modules from './modules';
import interceptors from './interceptors';

Vue.use(VueRouter);

const routes = [
  ...modules,
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'login' */ '@/views/login/index.vue'),
  },
  {
    path: '/notice-of-privacy',
    name: 'notice-of-privacy',
    component: () => import(/* webpackChunkName: 'login' */ '@/views/notice-of-privacy/index.vue'),
  },
  {
    path: '/password-recovery/email',
    name: 'password-recovery-email',
    component: () =>
      import(
        /* webpackChunkName: 'password-recovery-email' */ '@/views/password-recovery/Email.vue'
      ),
  },
  {
    path: '/password-recovery/reset/:token',
    name: 'password-recovery-reset',
    component: () =>
      import(
        /* webpackChunkName: 'password-recovery-reset' */ '@/views/password-recovery/Reset.vue'
      ),
    props: true,
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: 'signup' */ '@/views/signup/index.vue'),
  },
  {
    path: '/verify-email/:token',
    name: 'verify-email',
    component: () =>
      import(/* webpackChunkName: 'verify-email' */ '@/views/verify-email/index.vue'),
    props: true,
  },
  {
    path: '/',
    name: 'landing',
    component: () => import(/* webpackChunkName: 'landing' */ '@/views/landing/index.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: '404' */ '@/views/error/404.vue'),
  },
];

const router = new VueRouter({
  routes,
});

// Interceptors
router.afterEach(interceptors.afterEach);
router.beforeEach(interceptors.beforeEach);

export default router;

const initialState = () => ({
  showDrawer: false,
});

export default {
  namespaced: true,

  state: initialState(),

  mutations: {
    setDrawer(state, value) {
      state.showDrawer = value;
    },

    toggleDrawer(state) {
      state.showDrawer = !state.showDrawer;
    },
  },

  actions: {
  },
};

// Components
import Layout from '@/views/layout/index.vue';

export default {
  path: '/orders',
  component: Layout,
  children: [
    {
      path: '',
      name: 'order-list',
      component: () => import(/* webpackChunkName: 'order-list' */ '@/views/order/List.vue'),
    },
    {
      path: ':uuid?/form',
      name: 'order-form',
      component: () => import(/* webpackChunkName: 'order-form' */ '@/views/order/Form.vue'),
      props: true,
    },
    {
      path: ':uuid/tracking',
      name: 'order-tracking',
      component: () => import(/* webpackChunkName: 'order-tracking' */ '@/views/order/Tracking.vue'),
      props: true,
    },
    {
      path: ':uuid',
      name: 'order-detail',
      component: () => import(/* webpackChunkName: 'order-detail' */ '@/views/order/Detail.vue'),
      props: true,
    },
  ],
};

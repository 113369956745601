<template>
  <validation-observer
    ref="observer"
  >
    <v-form @submit.prevent="validate">
      <slot />
    </v-form>
  </validation-observer>
</template>

<script>
// Libraries
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'Form',

  components: {
    ValidationObserver,
  },

  methods: {
    submit() {
      this.$emit('submit');
    },

    reset() {
      this.$refs.observer.reset();
    },

    async validate() {
      const validForm = await this.$refs.observer.validate();

      if (validForm) {
        this.submit();
      } else {
        this.$store.dispatch('notification/notifyError', 'Corrige los errores.');
      }

      return validForm;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
